<template lang="">
  <BaseLayout mainClass="bg-oGray">
    <section class="px-5 md:px-16 lg:px-32 xl:px-40 2xl:px-40 bg-gray-100 ">
      <section>
        <div class="grid grid-cols-12 items-center  ">
          <div class="md:col-span-6 col-span-12  ">
            <div class="py-5">
              <h1
                class="font-black text-4xl  md:text-5xl lg:text-6xl  text-center md:text-left   "
              >
                Decentralized
                <br />
                <span class="text-color">Electric Vehicle</span>
                <br />
                Platform
              </h1>

              <div
                class="flex justify-center md:justify-start lg:justify-start xl:justify-start 2xl:justify-start"
              >
                <RouterLink
                  v-if="!isLoggedIn"
                  :to="{
                    name: 'Register',
                  }"
                >
                  <button
                    class="rounded-full bg-color text-oWhite text-lg py-2 px-4 mt-4"
                  >
                    Create Account
                  </button>
                </RouterLink>
              </div>
            </div>
          </div>
          <div
            class="md:col-span-6 col-span-12 flex justify-center order-first md:order-last lg:order-last xl:order-last 2xl:order-last py-5 "
          >
            <img
              src="@/assets/marketplace/product/aike/aike_3.jpg"
              alt="aike"
              class="w-auto h-auto rounded-lg"
            />
          </div>
        </div>
      </section>
      <section class="flex justify-center my-10">
        <div
          class="flex w-full md:w-1/2 items-center bg-white shadow rounded-md p-5"
        >
          <div class="bg-logo-color  rounded-lg">
            <img src="@/assets/marketplace/bar-chart.svg" alt="bar-chart" />
          </div>
          <div class="pl-4">
            <p class="text-xs font-bold">Annualized Income</p>
            <p class="text-lg font-bold">Up to 53-96%</p>
            <p class="text-xs">
              of the vehicle price On average you can earn your vehicle purchase
              price back in the 1st and 2nd year, and make profit from the 3rd
              year.
            </p>
          </div>
        </div>
      </section>
      <section class="my-10">
        <div class="flex justify-between">
          <h2 class="text-2xl md:text-3xl font-bold">Latest Orders</h2>
          <RouterLink
            :to="{
              name: 'MarketplaceAllPrimaryOrders',
            }"
          >
            <h4 class="text-xl font-bold">
              View All <i class="fas fa-arrow-right"></i>
            </h4>
          </RouterLink>
        </div>
        <div class="relative ">
          <div class="grid grid-cols-12 gap-4 mt-10 order-box ">
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              class="bg-gray-200"
            />
            <template v-for="(item, index) in preordersData">
              <PreorderCard
                :key="`preorderCard-${index}`"
                :rawdata="item"
                class="bg-white"
              />
            </template>
          </div>
        </div>
      </section>
      <section class="py-10">
        <div>
          <h2 class="text-2xl md:text-3xl  font-bold ">
            Why {{ BRAND_CONFIG.ORG_NAME }}?
          </h2>
          <div class="grid grid-cols-12 gap-4 mt-10">
            <template v-for="(item, index) in whyOtorideData">
              <div
                :key="`why-${index}`"
                class="col-span-12 md:col-span-6 lg:col-span-4 bg-white  hover:shadow-lg duration-200 ease-linear border border-gray-300 px-5 py-3 rounded-lg "
              >
                <div
                  class="w-full bg-logo-color  flex items-center justify-center h-48 rounded-lg"
                >
                  <img :src="item.image" :alt="item.title" />
                </div>
                <h3 class="text-xl font-bold py-2">{{ item.title }}</h3>
                <p>{{ item.description1 }}</p>
                <br />
                <p>{{ item.description2 }}</p>
              </div>
            </template>
          </div>
        </div>
      </section>
      <section class="py-10">
        <h2 class="text-2xl md:text-3xl  font-bold py-5">How it Works?</h2>
        <div class="grid grid-cols-12 items-center">
          <div class="md:col-span-6 col-span-12 py-5">
            <div>
              <h3
                class="px-3 py-2 border-l-4 border-gray-700 text-xl font-bold  cursor-pointer"
                @click="setHowItWorksActiveNumber(1)"
              >
                Register & Buy
              </h3>
              <p
                v-if="howItWorksActiveNumber === 1"
                class="px-3 py-2 border-l-4 border-gray-400 bg-white shadow-lg"
              >
                Create your vehicle owner account in seconds and select your
                preferred order, amount, and payment method. Trace every step of
                your order on the platform.
              </p>
            </div>
            <div>
              <h3
                class="px-3 py-2 border-l-4 border-gray-700 text-xl font-bold  cursor-pointer "
                @click="setHowItWorksActiveNumber(2)"
              >
                Rent & Earn
              </h3>
              <p
                v-if="howItWorksActiveNumber === 2"
                class="px-3 py-2 border-l-4 border-gray-400 bg-white shadow-lg"
              >
                Lease your purchased vehicles to us and monitor your earnings in
                real-time on the platform. Lean back and earn passive income.
              </p>
            </div>
            <div>
              <h3
                class=" px-3 py-2 border-l-4 border-gray-700 text-xl font-bold  cursor-pointer"
                @click="setHowItWorksActiveNumber(3)"
              >
                Payout & Buy More
              </h3>
              <p
                v-if="howItWorksActiveNumber === 3"
                class="px-3 py-2 border-l-4 border-gray-400 bg-white shadow-lg"
              >
                Lease your purchased vehicles to us and monitor your earnings in
                real-time on the platform. Lean back and earn passive income.
              </p>
            </div>
          </div>
          <div
            class=" h-80 md:col-span-6 col-span-12 flex justify-center md:justify-end order-first md:order-last lg:order-last xl:order-last 2xl:order-last"
          >
            <img
              v-if="howItWorksActiveNumber === 1"
              src="@/assets/marketplace/register-buy.png"
              alt="register-buy"
              class="w-auto h-auto "
            />
            <img
              v-if="howItWorksActiveNumber === 2"
              src="@/assets/marketplace/rent-earn.png"
              alt="register-buy"
              class="w-auto h-auto "
            />
            <img
              v-if="howItWorksActiveNumber === 3"
              src="@/assets/marketplace/payout-buy-more.png"
              alt="register-buy"
              class="w-auto h-auto"
            />
          </div>
        </div>
      </section>
    </section>
    <section>
      <Footer />
    </section>
  </BaseLayout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import PreorderCard from '@/composites/marketplace-buyer/preorder-card/PreorderCard.vue'
import Footer from '@/composites/marketplace-buyer/footer/Footer.vue'
import { useEndpoints } from '@/composables'
import { BRAND_CONFIG } from '@/config/brand.config'
export default {
  name: 'HomepageMarketplace',

  components: {
    BaseLayout,
    PreorderCard,
    Footer,
  },
  data: () => ({
    isLoading: false,
    BRAND_CONFIG: BRAND_CONFIG,
    howItWorksActiveNumber: 1,
    preordersData: [],
    whyOtorideData: [
      {
        title: 'Secure by Default',
        description1: `You own every vehicle you purchase from us. We rent and operate your vehicles up to five years. The vehicle rent income is split between you and us.`,
        description2: `That means your income is not just asset-backed but asset-owned giving you unmatched security!`,
        image: require('@/assets/marketplace/security.svg'),
      },
      {
        title: 'Transparent by Design',
        description1: `For gapless transparency, we have developed vehicle tracing from order to production, shipment, street deployment, and ongoing operations.`,
        description2: `Your dashboard gives you real-time insights into the vehicle fleet status and your income with ample drill-down and customization options.`,
        image: require('@/assets/marketplace/design.svg'),
      },
      {
        title: 'Fair on Purpose',
        description1: `It is your money and your vehicles so it is only fair that you get a significant share of the vehicle's income, in this case, up to 30%.`,
        description2: `And you don't have to wait for years or decades to earn and retrieve your rent income. You earn from your vehicle's first day on the street and can cash out any time.`,

        image: require('@/assets/marketplace/money.svg'),
      },
    ],
  }),
  created() {
    this.getPreorderList()
  },
  computed: {
    isLoggedIn() {
      if (this.$store.getters['auth/userInfo']) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getPreorderList() {
      this.isLoading = true
      await this.$http
        .get(useEndpoints.marketplaceBuyer.preorderIndex())
        .then((res) => {
          this.preordersData = res.data.data.filter((item, index) => index < 3)
        })
        .finally(() => (this.isLoading = false))
    },
    setHowItWorksActiveNumber(number) {
      this.howItWorksActiveNumber = number
    },
  },
}
</script>
<style lang="scss">
// .how-bg {
//   background-image: url('~@/assets/marketplace/how-bg-1.png');
//   background-repeat: no-repeat;
//   width: 100%;
//   background-size: cover;
//   background-position: bottom;
// }
.order-box {
  min-height: 100px;
}

.bg-color {
  background: var(--brand-primary-color);
}

.bg-logo-color {
  background: var(--homepage-svg-logo-bg-color);
}
.text-color {
  color: var(--homepage-brand-title-color);
}
</style>
